import React from 'react';
import linkedinLogo from '../assets/linkedin-icon.png';
import githubLogo from '../assets/github-icon.png';

const Bio = () => {
    return (
        <div className="bio-section">
            <h1>Cem Kupeli</h1>
            <p>4th year CS and Psychology student at Yale, Software Engineering Intern at&nbsp;
                <a href="https://www.goodnotes.com/" target="_blank" rel="noopener noreferrer">Goodnotes</a>
            </p>
            <div className="social-links">
                <a href="https://www.linkedin.com/in/cemkupeli/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinLogo} alt="LinkedIn Logo" className="social-logo" />
                    LinkedIn
                </a>
                <a href="https://github.com/cemkupeli" target="_blank" rel="noopener noreferrer">
                    <img src={githubLogo} alt="GitHub Logo" className="social-logo" />
                    GitHub
                </a>
            </div>
        </div>
        
    );
}

export default Bio;